<!--
 * @Author: your name
 * @Date: 2021-04-27 15:39:40
 * @LastEditTime: 2021-04-27 19:11:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\views\school.vue
-->
<template>
  <div class="container">
    <!-- <navigation msg="完善信息"></navigation> -->
     <div class="nac">
       <van-icon  @click="goback" size=".35rem" name="arrow-left"/>
      <div class="xxm">完善简历</div>
      <!-- <div class="del" @click="del" v-if="this.$route.query.item">删除</div> -->
    </div>
    <div class="msgclass">
      <div>添加教育经历</div>
      <div @click="$router.push('/school2')" class="themsgclass">
        <div>学校<span class="xh">*</span></div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input readonly="readonly" v-model="school" placeholder="请选择" />
        </div>
      </div>
      <div @click="clickmeth(1)" class="themsgclass">
        <div>学历<span class="xh">*</span></div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input
            readonly="readonly"
            v-model="education_background"
            placeholder="请选择"
          />
        </div>
      </div>
      <div class="themsgclass">
        <div>专业<span class="xh">*</span></div>
        <div>
          <div></div>
          <!-- <van-icon size=".2rem" color="#B3B3B3" name="arrow" /> -->
        </div>
        <div>
          <input v-model="major" placeholder="请填写" />
        </div>
      </div>
      <div @click="clickmeth(3)" class="themsgclass">
        <div>入学时间<span class="xh">*</span>：</div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input
            readonly="readonly"
            v-model="start_time"
            placeholder="请选择"
          />
        </div>
      </div>
      <div @click="clickmeth(4)" class="themsgclass">
        <div>毕业时间<span class="xh">*</span>：</div>
        <div>
          <div></div>
          <van-icon size=".2rem" color="#B3B3B3" name="arrow" />
        </div>
        <div>
          <input readonly="readonly" v-model="end_time" placeholder="请选择" />
        </div>
      </div>
    </div>
    <pack @packreturn="packreturn" ref="packid"></pack>
    <timees @timereturn="timereturn" ref="timeid"></timees>
    <div>
      <p class="btn3" @click="check" id="triggerBtn">确认</p>
      <p class="goback" v-if="type == 1" @click="del" id="triggerBtn">删除</p>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import pack from "../../components/pack";
import timees from "../../components/time";
// import getDate from "../../util/dateChange"
export default {
  data() {
    return {
      school: '',
      education_background: '',
      major: '',
      start_time: '',
      end_time: '',
      type:"",
      loack: true,
    };
  },
  components: {
    // navigation,
    pack,
   timees,
  },
  watch: {
    major(oldmsg) {
      window.major = oldmsg;
    },
  },
  mounted(){
    this.type = sessionStorage.getItem("type")
    if(sessionStorage.getItem("item") && sessionStorage.getItem("type") == 1){
      let item =JSON.parse(sessionStorage.getItem("item")) 
      let {school, education_background, major, start_time,end_time } = item
      this.school = school
      this.education_background = education_background
      this.major = major
      this.start_time = start_time
      this.end_time = end_time
    }
    if(this.$route.query.school){
      // console.log(this.$route.query.school)
      this.school = this.$route.query.school
    }
    
    
  },
  methods: {
    del(){
      let arr = JSON.parse(sessionStorage.getItem("educational_experience")) 
      let item =  JSON.parse(sessionStorage.getItem("item"))
      arr.forEach(function(item1,index){
        if(item1.id == item.id){
          // console.log(index)
          arr.splice(index,1)
          Toast("删除成功")
        }
      });
      this.$router.push("./resume1")
       sessionStorage.setItem("educational_experience",JSON.stringify(arr))
    },
    goback(){
      this.$router.push("./resume1")
    },
    check(){
      if(sessionStorage.getItem("type")== 1){
        //编辑
        let educational_experience = JSON.parse(sessionStorage.getItem("educational_experience"))
         let item = JSON.parse(sessionStorage.getItem("item")) 
      
          educational_experience.forEach(element => {
          if(element.id == item.id){
            element.school = this.school
            element.id = item.id
            element.education_background = this.education_background
            element.major = this.major
            element.start_time = this.start_time
            element.end_time = this.end_time
            // console.log(element.school)
          }
          return element
        });
       sessionStorage.setItem("educational_experience",JSON.stringify(educational_experience)) 
        this.$router.push('./resume1')
      }else{
        //新增
        if(this.school == ''){
        Toast("请输入学校")
        return
      }
      if(this.education_background == ''){
        Toast("请输入学历")
        return
      }
      if(this.major == ""){
        Toast("请填写专业")
        return
      }
      if(this.start_time == ""){
        Toast("请选择入学时间")
        return
      }
      if(this.end_time == ""){
        Toast("请选择毕业时间")
        return
      }
      
      let arr = JSON.parse(sessionStorage.getItem("educational_experience")) 
      // console.log(arr)
      let obj = {}
      obj.school = this.school
      obj.education_background =  this.education_background
      obj.start_time = this.start_time
      obj.end_time =  this.end_time
      obj.major = this.major
      arr.push(obj)
      arr = JSON.stringify(arr)
      sessionStorage.setItem("educational_experience",arr) 
      this.$router.push('./resume1')
       }
      
    },
    packreturn(msg, index) {
      if (index == 1) {
        //学历
        this.education_background = msg
        // localStorage.setItem("eduxl",msg)
      } else if (index == 2) {
        //专业
        this.major = msg;
      }
    },
    timereturn(msg, index) {
      // console.log(msg);
      if (index == 3) {
        //入学时间
        this.start_time = msg;
        let item = JSON.parse(sessionStorage.getItem("item")) 
        // console.log(item)
        if(item != null){
          item.start_time  = msg
        sessionStorage.setItem("item",JSON.stringify(item))
        }
        
        // localStorage.setItem("edurx",msg)
      } else if (index == 4) {
        //毕业时间
        this.end_time  = msg;
         let item = JSON.parse(sessionStorage.getItem("item")) 
        //  console.log(item)
         if(item != null){
           item.end_time  = msg
        sessionStorage.setItem("item",JSON.stringify(item))
         }
        
        // localStorage.setItem("eduby",msg)
      }
    },
    clickmeth(index) {
      if (index == 1) {
        this.$refs.packid.packmeth(
          ["初中及以下", "中专/中技", "高中", "大专", "本科", "硕士", "博士"],
          index
        );
      } else if (index == 2) {
        this.$refs.packid.packmeth(
          ["初中及以下", "中专/中技", "高中", "大专", "本科", "硕士", "博士"],
          index
        );
      } else if (index == 3) {
        this.$refs.timeid.timemeth(new Date(), index);
      } else if (index == 4) {
        this.$refs.timeid.timemeth(new Date(), index);
      }
    },
  },
};
</script>
<style scoped>
.goback{
  width: 6.86rem;
  height: 0.96rem;
  border-radius: 0.48rem;
  font-size: 0.36rem;
  line-height: 0.97rem;
  background: #F7F7F7;
  border: 0.02rem solid #FF5817;
  color:#FF5817;
  margin:0 auto 0.2rem;
  
  text-align: center;
}

.xxm {
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
}

.nac > :first-child {
    margin-left: 0.32rem;
}

.nac {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  padding: 0.3rem 0;
  position: relative;
  border-bottom-color: #ebedf0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.btn3 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  margin-left: 0.34rem;
  margin-bottom: 0.1rem;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
input {
  border: none;
}
.themsgclass {
  padding-top: 0.28rem;
  padding-bottom: 0.42rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d6d6d6;
  margin-bottom: 0.27rem;
}
.themsgclass > :first-child {
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #676767;
}
.themsgclass > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themsgclass > :last-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.msg .wan {
  margin-right: 0.34rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff4800;
}
.msgclass {
  margin: 0.46rem 0.38rem;
}
.msgclass > :first-child {
  font-size: 0.46rem;
  line-height: 0.65rem;
  font-weight: bold;
  margin-bottom: 0.18rem;
}
</style>
